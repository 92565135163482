.select-style {
  border-width: 0 0 1px 0;
  appearance: auto;
}

.form-control {
  border-radius: 0;
}

.btn-disabled {
  background-color: rgb(87, 87, 87) !important;
}

.btn-disabled:hover {
  background-color: rgb(87, 87, 87) !important;
}

.red-as {
  color: red !important;
}

.description p {
  /* text-align: justify; */
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px;
}

.description a {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #3e916f !important;
}

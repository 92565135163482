button {
  all: unset;
}

.section-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-cart {
  width: 760px;
  box-shadow: none;
}

.title1 {
  color: #fffae3 !important;
  font-size: 18px;
  font-weight: bolder;
}

.title2 {
  color: #fffae3 !important;
  font-size: 16px;
  font-weight: bolder;
}

.title2:hover {
  color: #fffae3 !important;
  cursor: default;
}

.date-purchase {
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid #efefef;
  justify-content: center;
}

.description-purchase {
  width: 65%;
  display: flex;
  padding-left: 10px;
}

.div-data-purchase {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.div-count-purchase {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-purchase {
  background-color: #3e916f;
  width: 25px;
  height: 25px;
  margin: 0 10px;
  font-size: 15px;
  color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-purchase:hover {
  background-color: #574139;
}

.count-purchase-disabled {
  background-color: rgb(173, 173, 173) !important;
  cursor: default !important;
}

@media (max-width: 800px) {
  .card-cart {
    width: 100%;
  }
}

.information {
  color: #3e916f !important;
}

.col strong {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #3e916f;
  text-transform: uppercase;
}

.col p {
  color: #efefef;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-top: 0.5rem;
}

.col .td-p {
  color: #efefef;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  margin: 0px;
}

.row {
  border-color: #36393a !important;
}
.col {
  background-color: #36393a !important;
}

h4 {
  color: #3e916f !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.extra {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #efefef !important;
}
.row {
  background-color: #282a2b !important;
}

.col {
  background-color: #36393a !important;
}
